import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid,
  Paper,
  Alert,
  ListItemIcon,
  Container,
  Avatar,
  Rating
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../../index.css';

const formatDate = (dateString, duration) => {
  const startDate = new Date(dateString.split(' to ')[0]);
  const endDate = new Date(dateString.split(' to ')[1]);
  return `🗓️ ${startDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}–${endDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} (Days: ${duration.split(' ')[0]})`;
};

const MainPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    
    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const featuredTrips = [
    {
      id: 1,
      title: "Alps Offroad Tour",
      subtitle: "Conquer the majestic peaks of the Alps",
      description: "Experience breathtaking mountain passes and challenging off-road trails through Europe's most iconic mountain range.",
      price: "€1,299",
      duration: "7 days",
      dates: [
        "2025-05-01 to 2025-05-07",
        "2025-06-15 to 2025-06-21",
        "2025-07-10 to 2025-07-16"
      ],
      image: "/images/alps-tour.jpg"
    },
    {
      id: 2,
      title: "Patagonia Expedition",
      subtitle: "Ride the edge of the world",
      description: "Journey through the stunning landscapes of Patagonia, from towering mountains to vast open plains.",
      price: "€2,499",
      duration: "10 days",
      dates: [
        "2025-05-01 to 2025-05-10"
      ],
      image: "/images/patagonia-tour.jpg"
    },
    {
      id: 3,
      title: "Morocco Desert Challenge",
      subtitle: "Master the Sahara's golden dunes",
      description: "Navigate through the mesmerizing desert landscapes and experience the rich culture of Morocco.",
      price: "€899",
      duration: "5 days",
      dates: [
        "2025-05-01 to 2025-05-05",
        "2025-06-01 to 2025-06-05",
        "2025-07-01 to 2025-07-05",
        "2025-08-01 to 2025-08-05"
      ],
      image: "/images/morocco-tour.jpg"
    }
  ];

  const testimonials = [
    {
      id: 1,
      name: "John D.",
      location: "UK",
      text: "Best off-road trip ever! The guide was awesome.",
      rating: 5,
      image: "/images/testimonial1.jpg"
    },
    {
      id: 2,
      name: "Sarah M.",
      location: "USA",
      text: "Unforgettable ride through Patagonia. Highly recommend!",
      rating: 5,
      image: "/images/testimonial2.jpg"
    }
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          height: '40vh',
          width: '100vw',
          backgroundImage: 'url(images/hero-bg.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }
        }}
      >
        <Box sx={{ position: 'relative', zIndex: 1, textAlign: 'center', color: 'white', px: 3 }}>
          <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', mb: 2, fontSize: '2.5rem' }}>
            Find Your Next Motorcycle Adventure
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mb: 4, fontSize: '1.5rem' }}>
            Explore curated offroad & touring trips. Connect with guides. Ride epic routes.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/adventure-packages')}
              sx={{
                backgroundColor: '#646464',
                '&:hover': { backgroundColor: '#7f7e7a' }
              }}
            >
              Find a Trip
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={() => navigate('/adventure-packages')}
              sx={{
                color: 'white',
                borderColor: 'white',
                '&:hover': { borderColor: 'white', backgroundColor: 'rgba(255, 255, 255, 0.1)' }
              }}
            >
              List Your Trip
            </Button>
          </Box>
        </Box>
      </Box>

      {/* How It Works Section */}
      <Container sx={{ py: 8 }}>
        <Typography variant="h3" align="center" gutterBottom sx={{ color: '#646464', mb: 6 }}>
          How It Works
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <SearchIcon sx={{ fontSize: 60, color: '#646464', mb: 2 }} />
              <Typography variant="h5" gutterBottom sx={{ color: '#646464' }}>
                Discover
              </Typography>
              <Typography>
                Browse handpicked motorcycle adventures
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <CalendarMonthIcon sx={{ fontSize: 60, color: '#646464', mb: 2 }} />
              <Typography variant="h5" gutterBottom sx={{ color: '#646464' }}>
                Book
              </Typography>
              <Typography>
                Secure your spot with trusted guides & operators
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <TwoWheelerIcon sx={{ fontSize: 60, color: '#646464', mb: 2 }} />
              <Typography variant="h5" gutterBottom sx={{ color: '#646464' }}>
                Ride
              </Typography>
              <Typography>
                Enjoy an unforgettable trip with like-minded riders
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Featured Trips Section */}
      <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
        <Container>
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#646464', mb: 6 }}>
            Featured Motorcycle Trips
          </Typography>
          <Grid container spacing={4}>
            {featuredTrips.map((trip) => (
              <Grid item xs={12} md={4} key={trip.id}>
                <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ 
                    height: '200px', 
                    overflow: 'hidden',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px'
                  }}>
                    <img
                      src={trip.image}
                      alt={trip.title}
                      style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </Box>
                  <Box sx={{ flexGrow: 1, p: 3 }}>
                    <Typography 
                      variant="h5" 
                      component="h2" 
                      sx={{ 
                        color: '#646464',
                        fontWeight: 'bold',
                        mb: 1
                      }}
                    >
                      {trip.title}
                    </Typography>
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        color: '#4a4a4a',
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      ✦ {trip.subtitle}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: '#646464',
                        mb: 2,
                        minHeight: '3em'
                      }}
                    >
                      {trip.description}
                    </Typography>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 1,
                      mb: 2
                    }}>
                      <Typography variant="h6" sx={{ color: '#4a4a4a' }}>
                        💰 {trip.price}
                      </Typography>
                      {trip.dates.length === 1 ? (
                        <Typography variant="body2" sx={{ color: '#4a4a4a' }}>
                          {formatDate(trip.dates[0], trip.duration)}
                        </Typography>
                      ) : (
                        <Typography variant="body2" sx={{ color: '#4a4a4a' }}>
                          🗓️ Multiple dates available
                        </Typography>
                      )}
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => navigate(`/trip/${trip.id}`)}
                      sx={{
                        backgroundColor: '#646464',
                        '&:hover': { backgroundColor: '#7f7e7a' }
                      }}
                    >
                      View Trip
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Why Travel-Riders Section */}
      <Container sx={{ py: 8 }}>
        <Typography variant="h3" align="center" gutterBottom sx={{ color: '#646464', mb: 6 }}>
          Why Travel-Riders?
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Box sx={{ textAlign: 'center' }}>
              <CheckCircleIcon sx={{ fontSize: 40, color: '#646464', mb: 2 }} />
              <Typography variant="h6" gutterBottom sx={{ color: '#646464' }}>
                Handpicked Adventures
              </Typography>
              <Typography>
                Only top-rated guides & operators
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ textAlign: 'center' }}>
              <CheckCircleIcon sx={{ fontSize: 40, color: '#646464', mb: 2 }} />
              <Typography variant="h6" gutterBottom sx={{ color: '#646464' }}>
                Authentic Riding Experience
              </Typography>
              <Typography>
                No tourist traps, just real adventure
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ textAlign: 'center' }}>
              <CheckCircleIcon sx={{ fontSize: 40, color: '#646464', mb: 2 }} />
              <Typography variant="h6" gutterBottom sx={{ color: '#646464' }}>
                Secure Booking
              </Typography>
              <Typography>
                Safe payments & verified operators
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ textAlign: 'center' }}>
              <CheckCircleIcon sx={{ fontSize: 40, color: '#646464', mb: 2 }} />
              <Typography variant="h6" gutterBottom sx={{ color: '#646464' }}>
                Community of Riders
              </Typography>
              <Typography>
                Join a network of passionate motorcyclists
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Testimonials Section */}
      <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
        <Container>
          <Typography variant="h3" align="center" gutterBottom sx={{ color: '#646464', mb: 6 }}>
            What Our Riders Say
          </Typography>
          <Grid container spacing={4}>
            {testimonials.map((testimonial) => (
              <Grid item xs={12} md={6} key={testimonial.id}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar src={testimonial.image} sx={{ width: 56, height: 56, mr: 2 }} />
                    <Box>
                      <Typography variant="h6" sx={{ color: '#646464' }}>
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.location}
                      </Typography>
                    </Box>
                  </Box>
                  <Rating value={testimonial.rating} readOnly sx={{ mb: 2 }} />
                  <Typography variant="body1">
                    "{testimonial.text}"
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Final CTA Section */}
      <Box sx={{ py: 8, textAlign: 'center' }}>
        <Container>
          <Typography variant="h3" gutterBottom sx={{ color: '#646464', mb: 4 }}>
            Start Your Next Adventure Today!
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/adventure-packages')}
            sx={{
              backgroundColor: '#646464',
              '&:hover': { backgroundColor: '#7f7e7a' }
            }}
          >
            Find Your Ride
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default MainPage; 