import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import '../../../index.css';

const RefundCancellationPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#646464' }}>
          Refund and Cancellation Policy
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            This policy outlines the terms and conditions for cancellations and refunds of motorcycle tours booked through Travel-Riders.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            1. Cancellation by Customer
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>More than 30 days before departure: Full refund minus processing fee (5%)</li>
            <li>15-30 days before departure: 50% refund</li>
            <li>Less than 15 days before departure: No refund</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            2. Cancellation by Operator
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            If a tour is cancelled by the operator, customers will receive a full refund or the option to reschedule to another available date.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            3. Force Majeure
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            In case of force majeure events (natural disasters, political unrest, etc.), we will work with operators to provide alternative dates or partial refunds based on incurred costs.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            4. Refund Process
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Refunds are processed within 7-10 business days</li>
            <li>Refunds are issued to the original payment method</li>
            <li>Processing fees are non-refundable</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            5. Contact Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            For cancellation requests or questions about refunds, please contact us at:
          </Typography>
          <Typography variant="body1" sx={{ color: '#646464' }}>
            Email: support@travel-riders.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default RefundCancellationPolicyPage; 