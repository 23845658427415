import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import '../../../index.css';

const LegalNoticePage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#646464' }}>
          Legal Notice (Impressum)
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464' }}>
            Company Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Travel-Riders GmbH<br />
            Motorcycle Adventure Platform<br />
            Musterstraße 123<br />
            10115 Berlin<br />
            Germany
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            Contact Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Phone: +49 (0) 30 12345678<br />
            Email: info@travel-riders.com<br />
            Website: www.travel-riders.com
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            Legal Representatives
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Managing Director: John Doe<br />
            Commercial Register: Amtsgericht Berlin-Charlottenburg<br />
            Registration Number: HRB 123456
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            VAT Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            VAT Identification Number: DE123456789
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            Responsible for Content
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            John Doe<br />
            Travel-Riders GmbH<br />
            Musterstraße 123<br />
            10115 Berlin<br />
            Germany
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            Regulatory Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Travel-Riders is registered with the German Federal Financial Supervisory Authority (BaFin) as a travel agency.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default LegalNoticePage; 