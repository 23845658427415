import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import '../../../index.css';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#646464' }}>
          Privacy Policy
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            At Travel-Riders, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            We collect information that you provide directly to us, including but not limited to:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Personal identification information (name, email address, phone number)</li>
            <li>Account credentials</li>
            <li>Payment information</li>
            <li>Travel preferences and requirements</li>
            <li>Communication preferences</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            We use the information we collect to:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Provide and maintain our services</li>
            <li>Process your bookings and payments</li>
            <li>Communicate with you about your bookings and our services</li>
            <li>Improve our website and services</li>
            <li>Comply with legal obligations</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            3. Data Security
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            4. Your Rights
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            You have the right to:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Object to processing of your information</li>
            <li>Request data portability</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            5. Contact Us
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            If you have any questions about this Privacy Policy, please contact us at:
          </Typography>
          <Typography variant="body1" sx={{ color: '#646464' }}>
            Email: privacy@travel-riders.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicyPage; 