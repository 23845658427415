import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import '../../../index.css';

const TermsOfServicePage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#646464' }}>
          Terms of Service
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Welcome to Travel-Riders. By accessing or using our website, you agree to be bound by these Terms of Service. Please read them carefully.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            By accessing or using Travel-Riders, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing this site.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            2. Use License
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Permission is granted to temporarily download one copy of the materials on Travel-Riders' website for personal, non-commercial transitory viewing only.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            3. User Responsibilities
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Provide accurate and complete information</li>
            <li>Maintain the security of your account</li>
            <li>Comply with all applicable laws and regulations</li>
            <li>Respect the rights of other users</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            4. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Travel-Riders shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the service.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            5. Contact Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            If you have any questions about these Terms of Service, please contact us at:
          </Typography>
          <Typography variant="body1" sx={{ color: '#646464' }}>
            Email: legal@travel-riders.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsOfServicePage; 