import React from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Paper,
  Button,
  Grid
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import '../../index.css';

const TripDetailsPage = () => {
  const navigate = useNavigate();
  const { tripId } = useParams();

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
        <Box sx={{ mb: 4 }}>
          <CheckCircleOutlineIcon 
            sx={{ 
              fontSize: 60, 
              color: '#646464',
              mb: 2
            }} 
          />
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#646464' }}>
            Thank You for Your Interest!
          </Typography>
          <Typography variant="h6" sx={{ color: '#646464', mb: 4 }}>
            We're working with top operators to bring you the best motorcycle adventures
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            We're currently in the process of partnering with experienced motorcycle tour operators to provide you with unforgettable adventures. Our team is carefully selecting and vetting operators to ensure the highest quality experiences.
          </Typography>
        </Box>

        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              onClick={() => navigate('/')}
              sx={{
                backgroundColor: '#646464',
                '&:hover': { backgroundColor: '#7f7e7a' }
              }}
            >
              Back to Adventure Packages
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default TripDetailsPage; 