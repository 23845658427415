import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import '../../../index.css';

const CookiePolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#646464' }}>
          Cookie Policy
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            This Cookie Policy explains how Travel-Riders uses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            1. What are cookies?
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            2. Why do we use cookies?
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            We use cookies for several reasons:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Essential cookies: Required for the website to function properly</li>
            <li>Performance cookies: Help us understand how visitors interact with our website</li>
            <li>Functionality cookies: Remember your preferences and settings</li>
            <li>Targeting cookies: Used to deliver relevant content and advertisements</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            3. How can you control cookies?
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided in our cookie banner.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            4. Contact Us
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            If you have any questions about our use of cookies, please contact us at:
          </Typography>
          <Typography variant="body1" sx={{ color: '#646464' }}>
            Email: privacy@travel-riders.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default CookiePolicyPage; 