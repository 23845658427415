import React, { useState } from "react";
import { 
  ListItemIcon, 
  MenuItem, 
  MenuList, 
  Tooltip, 
  Typography,
  IconButton,
  Drawer,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoginLogoutButton from "../LoginLogoutButton";
import { useNavigate } from 'react-router-dom';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MapIcon from '@mui/icons-material/Map';
import SchoolIcon from '@mui/icons-material/School';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import '../../index.css';

const TopMenu = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [toolkitAnchorEl, setToolkitAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToolkitClick = (event) => {
    setToolkitAnchorEl(event.currentTarget);
  };

  const handleToolkitClose = () => {
    setToolkitAnchorEl(null);
  };

  const handleSubmenuClick = (path) => {
    navigate(path);
    handleToolkitClose();
    if (isMobile) setMobileOpen(false);
  };

  const mainMenuItems = [
    { 
      icon: <HomeIcon className="whiteIcon" />, 
      text: "Home", 
      path: "/", 
      tooltip: "Return to the main page" 
    },
    { 
      icon: <AutoStoriesIcon className="whiteIcon" />, 
      text: "Riders Tales", 
      path: "/riders-tales", 
      tooltip: "Read stories from fellow riders" 
    },
    { 
      icon: <LocalOfferIcon className="whiteIcon" />, 
      text: "Adventure Packages", 
      path: "/adventure-packages", 
      tooltip: "Explore curated motorcycle adventure packages" 
    },
    { 
      icon: <SchoolIcon className="whiteIcon" />, 
      text: "Academy", 
      path: "/academy", 
      tooltip: "Access motorcycle training courses, safety tips, and riding techniques" 
    }
  ];

  const toolkitSubmenuItems = [
    { 
      icon: <MapIcon className="whiteIcon" />, 
      text: "Plan My Route", 
      path: "/map", 
      tooltip: "Plan your motorcycle route" 
    },
    { 
      icon: <WbSunnyIcon className="whiteIcon" />, 
      text: "Weather", 
      path: "/weather", 
      tooltip: "Check weather conditions and forecasts" 
    },
    { 
      icon: <MenuBookIcon className="whiteIcon" />, 
      text: "Riders Guide", 
      path: "/riders-guide", 
      tooltip: "Essential tips and guides for motorcycle travelers" 
    }
  ];

  const renderMainMenuItems = () => (
    mainMenuItems.map((item, index) => (
      <Tooltip key={index} title={item.tooltip} arrow placement="bottom">
        <MenuItem 
          onClick={() => {
            navigate(item.path);
            if (isMobile) setMobileOpen(false);
          }}
          sx={{ color: '#646464' }}
        >
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <Typography variant="body1">{item.text}</Typography>
        </MenuItem>
      </Tooltip>
    ))
  );

  const renderToolkitSubmenu = () => (
    <Box>
      <Tooltip title="Access essential tools for your motorcycle journey" arrow placement="bottom">
        <MenuItem
          onClick={handleToolkitClick}
          sx={{ color: '#646464' }}
        >
          <ListItemIcon>
            <MapIcon className="whiteIcon" />
          </ListItemIcon>
          <ListItemText primary="Rider's Toolkit" />
          <KeyboardArrowDownIcon />
        </MenuItem>
      </Tooltip>
      <Menu
        anchorEl={toolkitAnchorEl}
        open={Boolean(toolkitAnchorEl)}
        onClose={handleToolkitClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {toolkitSubmenuItems.map((item, index) => (
          <Tooltip key={index} title={item.tooltip} arrow placement="right">
            <MenuItem
              onClick={() => handleSubmenuClick(item.path)}
              sx={{ color: '#646464' }}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </Box>
  );

  const renderMobileMenuItems = () => (
    <Box>
      {mainMenuItems.map((item, index) => (
        <Tooltip key={index} title={item.tooltip} arrow placement="bottom">
          <MenuItem 
            onClick={() => {
              navigate(item.path);
              setMobileOpen(false);
            }}
            sx={{ color: '#646464' }}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <Typography variant="body1">{item.text}</Typography>
          </MenuItem>
        </Tooltip>
      ))}
      <MenuItem sx={{ color: '#646464' }}>
        <ListItemIcon>
          <MapIcon className="whiteIcon" />
        </ListItemIcon>
        <Typography variant="body1">Rider's Toolkit</Typography>
      </MenuItem>
      {toolkitSubmenuItems.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            navigate(item.path);
            setMobileOpen(false);
          }}
          sx={{ color: '#646464', pl: 4 }}
        >
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <Typography variant="body1">{item.text}</Typography>
        </MenuItem>
      ))}
    </Box>
  );

  return (
    <div className="topmenu">
      <div className="logo-container">
        <img 
          src="/logo2.png" 
          alt="Travel-Riders Logo" 
          style={{ 
            height: '100px',
            marginBottom: '10px'
          }}
        />
      </div>
      {isMobile ? (
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, color: '#646464' }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', 
                width: '100%',
                maxWidth: '400px'
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              <MenuList>
                {renderMobileMenuItems()}
                <LoginLogoutButton />
              </MenuList>
            </Box>
          </Drawer>
        </Box>
      ) : (
        <div className="menu-container">
          <MenuList className="horizontal-menu">
            {mainMenuItems.slice(0, 2).map((item, index) => (
              <Tooltip key={index} title={item.tooltip} arrow placement="bottom">
                <MenuItem 
                  onClick={() => {
                    navigate(item.path);
                    if (isMobile) setMobileOpen(false);
                  }}
                  sx={{ color: '#646464' }}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <Typography variant="body1">{item.text}</Typography>
                </MenuItem>
              </Tooltip>
            ))}
            {renderToolkitSubmenu()}
            {mainMenuItems.slice(2).map((item, index) => (
              <Tooltip key={index} title={item.tooltip} arrow placement="bottom">
                <MenuItem 
                  onClick={() => {
                    navigate(item.path);
                    if (isMobile) setMobileOpen(false);
                  }}
                  sx={{ color: '#646464' }}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <Typography variant="body1">{item.text}</Typography>
                </MenuItem>
              </Tooltip>
            ))}
            <LoginLogoutButton />
          </MenuList>
        </div>
      )}
    </div>
  );
};

export default TopMenu;
