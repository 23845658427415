import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const WeatherPage = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: '1200px', mx: 'auto' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#646464', mb: 3 }}>
          Weather Forecast
        </Typography>
        <Box sx={{ 
          width: '100%', 
          height: '600px',
          borderRadius: '8px',
          overflow: 'hidden'
        }}>
          <iframe
            src="https://embed.windy.com/embed2.html?lat=40.7128&lon=-74.0060&detailLat=40.7128&detailLon=-74.0060&width=100%&height=100%&zoom=5&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Windy Weather Map"
            style={{ border: 'none' }}
          />
        </Box>
        <Typography variant="body1" sx={{ mt: 3, color: '#646464' }}>
          Use the interactive map above to check weather conditions for your planned routes. 
          You can view wind patterns, temperature, precipitation, and other weather data to help plan your motorcycle adventure.
        </Typography>
      </Paper>
    </Box>
  );
};

export default WeatherPage; 