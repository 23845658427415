import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import '../../../index.css';

const SellerOperatorAgreementPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#646464' }}>
          Seller/Operator Agreement
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            This agreement outlines the terms and conditions for motorcycle tour operators and sellers who wish to list their services on Travel-Riders.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            1. Eligibility Requirements
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Valid business license and insurance</li>
            <li>Minimum 2 years of operation</li>
            <li>Positive customer reviews and reputation</li>
            <li>Compliance with local regulations</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            2. Listing Requirements
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Accurate and detailed tour descriptions</li>
            <li>High-quality images of tours and equipment</li>
            <li>Clear pricing and cancellation policies</li>
            <li>Regular availability updates</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            3. Commission and Payments
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            Travel-Riders charges a commission of 15% on all bookings made through our platform. Payments are processed monthly, with a 30-day payment term.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            4. Quality Standards
          </Typography>
          <Typography variant="body1" component="ul" sx={{ color: '#646464', pl: 4 }}>
            <li>Maintain high safety standards</li>
            <li>Provide excellent customer service</li>
            <li>Respond to inquiries within 24 hours</li>
            <li>Honor all bookings and commitments</li>
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: '#646464', mt: 3 }}>
            5. Contact Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#646464' }}>
            For questions about becoming a seller/operator, please contact us at:
          </Typography>
          <Typography variant="body1" sx={{ color: '#646464' }}>
            Email: partners@travel-riders.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default SellerOperatorAgreementPage; 